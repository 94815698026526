import compose from "recompose/compose";
import withTrackPage from "theme/modules/Analytics/withTrackPage";
import withTrackOnMount from "theme/modules/Analytics/withTrackOnMount";

export default () =>
  compose(
    withTrackPage("Post"),
    withTrackOnMount({
      event: "Blog Post viewed",
      isResolvedFromProps: (props) => !props.loading && props.post,
      shouldUpdateEvent: (prevProps, nextProps) =>
        prevProps.post.post_id !== nextProps.post.post_id,
      mapPropsToProperties: ({ post }) => ({
        name: post.title,
        category: post.path,
      }),
    })
  );
