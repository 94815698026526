import React from "react";
import { compose } from "recompose";
import PostCard from "./PostCard";
import Loadable from "theme/components/helpers/Loadable";

const PostsList = ({ posts }) => {
  const nbColumns = 3;
  let count = 0;

  return (
    <div className="posts">
      <div className="container">
        <div className="post-list-container item-list-3-cols">
          {posts?.map((post, index) => {
            count = index % (nbColumns * 2);
            return (
              <PostCard
                count={count}
                key={post.post_id}
                shortContent={post.shortContentWysiwyg}
                thumbnail={post.featuredImg}
                featuredImgAlt={post.featured_img_alt}
                titleWysiwyg={post.titleWysiwyg}
                title={post.title}
                createdAt={post.created_at}
                tags={post.tags}
                path={post.url_key}
              />
            );
          })}
        </div>
        <div className="clearfix" />
      </div>
    </div>
  );
};

export default compose(Loadable((props) => !props.loading))(PostsList);
