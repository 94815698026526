module.exports = {
  defaultBgColor: "FFFFFF",
  presets: {
    swatch: { width: 25, height: 25, bgColors: [] },
    thumbnail: { width: 90, height: 130, bgColors: [] },
    small: { width: 136, height: 167, bgColors: [] },
    galleryModal: { width: 624, height: 894, bgColors: [], sizes: [1] },
    galleryPreview: { width: 136, height: 136, bgColors: [] },
    productColors: { width: 60, height: 60, bgColors: [], sizes: [1] },
    sendFriend: { width: 67, height: 96, bgColors: [], sizes: [0.5, 1] },
    medium: {
      width: 775,
      height: 1110,
      bgColors: [],
      sizes: [1],
    },
    imageGalleryNoRatio: {
      width: 710,
      // the placeholder image may have a different height than the loaded image
      // when you have a list of images but don't actually know the ratio of the final image
      // you can replace the height with placeholderHeight in the preset
      placeholderHeight: 1088,
      bgColors: [],
      sizes: [0.5, 0.6, 1],
    },
    newsThumbnail: {
      width: 450,
      height: 450,
      bgColors: [],
      sizes: [1],
    },
    imageWidgetTwoProductsSlide: {
      width: 553,
      placeholderHeight: 748,
      bgColors: [],
      sizes: [0.5, 0.6, 1],
    },
    imageWidgetTwoProductsSlideMobile: {
      width: 533,
      height: 748,
      placeholderHeight: 553,
      bgColors: [],
      sizes: [0.5, 0.6, 1],
    },
    catNiv4: {
      width: 386,
      placeholderHeight: 378,
      bgColors: [],
      sizes: [1],
    },
    large: { width: 1100, height: 1100, bgColors: [] },
    newsletter: { width: 1280, height: 242, bgColors: [] },
    carousel: { width: 1280, height: 600, bgColors: [] },
    pushBlock: { width: 284, height: 353, bgColors: [] },
    pushBlockWide: { width: 568, height: 353, bgColors: [] },
    cartPreview: { width: 90, height: 130, bgColors: [], sizes: [0.5, 1] },
    orderPreview: { width: 68, height: 100, bgColors: [], sizes: [0.5, 1] },
    orderLitePreview: {
      width: 106,
      placeholderHeight: 162,
      bgColors: [],
      sizes: [0.5, 1],
    },
    wishlistPreview: { width: 50, height: 50, bgColors: [] },
    catMenu: { width: 711, height: 711, bgColors: [], sizes: [1.5, 1] },
    widgetDoubleIllustrationSame: {
      width: 866,
      placeholderHeight: 1088,
      bgColors: [],
      sizes: [0.5, 0.6, 1],
    },
    widgetDoubleIllustrationBigSmall: {
      width: 592,
      placeholderHeight: 888,
      bgColors: [],
      sizes: [1],
    },
    widgetDoubleIllustrationInside: {
      width: 1900,
      placeholderHeight: 1400,
      bgColors: [],
      sizes: [1],
    },
    widgetDoubleIllustrationOutsideBig: {
      width: 537,
      placeholderHeight: 805,
      bgColors: [],
      sizes: [1],
    },
    widgetDoubleIllustrationOutsideSmall: {
      width: 286,
      placeholderHeight: 430,
      bgColors: [],
      sizes: [1],
    },
  },
  extensions: [".jpg", ".jpeg", ".png"],
};
