import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import { useLocation, useHistory } from "react-router";
import { defineMessages, useIntl } from "react-intl";
import qs from "web/core/utils/queryString";
import { Helmet } from "react-helmet-async";
import Icon from "theme/components/atoms/Icon";
import useFullUrl from "web/core/shop/useFullUrl";
import { useShop } from "web/core/shop/ShopContext";

const messages = defineMessages({
  previous: {
    id: "components.molecules.Paginator.previous",
    defaultMessage: "Previous",
  },
  next: {
    id: "components.molecules.Paginator.next",
    defaultMessage: "Next",
  },
});

const getPageUrl = (location, page) => {
  const { pathname, search, hash } = location;
  let query = { ...qs.parse(search), page: page };
  if (query.page === 1) {
    delete query.page;
  }
  const updatedSearch = qs.stringify(query);
  return (
    pathname +
    (updatedSearch.length > 0 ? `?${updatedSearch}` : "") +
    (hash?.length > 0 ? hash : "")
  );
};

const Paginator = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const originalLocation = useLocation();
  const location = props.location ?? originalLocation;
  const shop = useShop();
  const pageRangeDisplayed = props.pageRangeDisplayed
    ? props.pageRangeDisplayed
    : 4;
  const prevUrl = useFullUrl(
    props.page === 2 ? location.pathname : getPageUrl(location, props.page - 1)
  );
  const nextUrl = useFullUrl(getPageUrl(location, props.page + 1));

  const pageClick = (data) => {
    const pageUrl = getPageUrl(location, data.selected + 1);
    history.push(pageUrl);
  };

  return (
    <>
      <Helmet>
        {props.page > 1 && <link rel="prev" href={prevUrl} />}
        {props.page > 1 && <meta name="robots" content="noindex,Follow"/>}
        {props.page < props.maxPage && <link rel="next" href={nextUrl} />}
      </Helmet>

      <ReactPaginate
        previousLabel={
          <>
            <span className="left">
              <Icon
                icon="chevron-left-revert"
                size="small"
                title={intl.formatMessage(messages.previous)}
              />
            </span>
          </>
        }
        nextLabel={
          <span className="right">
            <Icon
              icon="chevron-right-revert"
              size="small"
              title={intl.formatMessage(messages.next)}
            />
          </span>
        }
        pageCount={props.maxPage}
        forcePage={props.page ? props.page - 1 : 0}
        marginPagesDisplayed={props.pageRangeDisplayed ? 1 : 0}
        pageRangeDisplayed={pageRangeDisplayed}
        breakLabel={props.pageRangeDisplayed ? "..." : ""}
        containerClassName="paginator"
        breakClassName="paginator__page"
        breakLinkClassName="paginator__link"
        pageClassName="paginator__page"
        pageLinkClassName="paginator__link"
        previousClassName="paginator__page paginator__page--previous"
        previousLinkClassName="paginator__link"
        nextClassName="paginator__page paginator__page--next"
        nextLinkClassName="paginator__link"
        activeClassName={"paginator__page--active"}
        disabledClassName={"paginator__page--disabled"}
        onPageChange={pageClick}
        hrefBuilder={(index) => {
          return (
            shop.baseUrl +
            (index === 1 ? getPageUrl(location) : getPageUrl(location, index))
          );
        }}
      />
    </>
  );
};

Paginator.propTypes = {
  maxPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

export default Paginator;
