import React from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import useFullUrl from "web/core/shop/useFullUrl";
import qs from "qs";

const CategoryPostSeo = ({ categoryPost, canonicalUrl }) => {
  const history = useHistory();
  const url = useFullUrl(categoryPost.path);
  const canonical = canonicalUrl ?? url;

  // change title for pages 2 and more
  const { page } = qs.parse(history.location.search.slice(1)); // page 2
  const title = categoryPost.meta_title || categoryPost.title;
  const pageMore = title + ` - P.${page}`; // Post - P.2

  return (
    <Helmet encodeSpecialCharacters={false}>
      <title>{page > 1 ? pageMore : title}</title>
      {categoryPost.meta_keywords && (
        <meta name="keywords" content={categoryPost.meta_keywords} />
      )}
      {categoryPost.meta_description && (
        <meta name="description" content={categoryPost.meta_description} />
      )}
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};

export default CategoryPostSeo;
