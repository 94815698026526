import compose from "recompose/compose";
import withTrackPage from "theme/modules/Analytics/withTrackPage";
import withTrackOnMount from "theme/modules/Analytics/withTrackOnMount";

export default () =>
  compose(
    withTrackPage("CategoryPost"),
    withTrackOnMount({
      event: "Blog Category viewed",
      isResolvedFromProps: (props) => !props.loading && props.categoryPost,
      shouldUpdateEvent: (prevProps, nextProps) =>
        prevProps.categoryPost.category_id !==
        nextProps.categoryPost.category_id,
      mapPropsToProperties: ({ categoryPost }) => ({
        name: categoryPost.title,
        Path2D: categoryPost.path,
      }),
    })
  );
