module.exports = {
  kaporal_fr_fr: {
    id: 1,
    url:
      process.env.FRONT_COMMERCE_URL + process.env.FRONT_COMMRECE_URL_FR_BASE,
    assetsBaseUrl:
      process.env.FRONT_COMMERCE_CDN_URL +
      process.env.FRONT_COMMRECE_URL_FR_BASE,
    locale: "fr-FR",
    currency: "EUR",
    default_country_id: "FR",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/fr.json")),
  },
  kaporal_fr_en: {
    id: 2,
    url:
      process.env.FRONT_COMMERCE_URL + process.env.FRONT_COMMRECE_URL_EN_BASE,
    assetsBaseUrl:
      process.env.FRONT_COMMERCE_CDN_URL +
      process.env.FRONT_COMMRECE_URL_EN_BASE,
    locale: "en-GB",
    currency: "EUR",
    default_country_id: "GB",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json")),
  },
  kaporal_fr_de: {
    id: 5,
    url:
      process.env.FRONT_COMMERCE_URL + process.env.FRONT_COMMRECE_URL_DE_BASE,
    assetsBaseUrl:
      process.env.FRONT_COMMERCE_CDN_URL +
      process.env.FRONT_COMMRECE_URL_DE_BASE,
    locale: "de-DE",
    currency: "EUR",
    default_country_id: "DE",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/de.json")),
  },
  kaporal_fr_es: {
    id: 6,
    url:
      process.env.FRONT_COMMERCE_URL + process.env.FRONT_COMMRECE_URL_ES_BASE,
    assetsBaseUrl:
      process.env.FRONT_COMMERCE_CDN_URL +
      process.env.FRONT_COMMRECE_URL_ES_BASE,
    locale: "es-ES",
    currency: "EUR",
    default_country_id: "ES",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/es.json")),
  },
};
