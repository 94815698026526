import React from "react";
import useLayerPagination from "./useLayerPagination";
import Paginator from "theme/components/molecules/Paginator";
import RedirectToPageOne from "theme/modules/Layer/LayerPagination/RedirectToPageOne";

const LayerPagination = ({ layer, postsPerPage }) => {
  const [page, maxPage, location, amontHasPages] = useLayerPagination(
    layer,
    postsPerPage
  );
  if (maxPage === 0) {
    return null;
  }
  if (page > maxPage) {
    return <RedirectToPageOne location={location} />;
  }

  return maxPage > 1 ||
    (maxPage === 1 && layer.posts.length === 0 && layer.total > 0) ? (
    <>
      <span className="paginator__total">
        {amontHasPages} / {layer.total}
      </span>
      <Paginator maxPage={maxPage} page={page} location={location} />
    </>
  ) : null;
};

export default LayerPagination;
