import React from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router";
import Loadable from "theme/components/helpers/Loadable";
import EnhanceCategoryPost from "./EnhanceCategoryPost";
import CategoryPostQuery from "./CategoryPostQuery.gql";
import PostList from "theme/modules/Posts/CategoryPost/PostsList";
import WysiwygV2 from "theme/modules/WysiwygV2";
import LayerPage from "theme/modules/Posts/CategoryPost/Layer/LayerPage/LayerPage";
import Stack from "theme/components/atoms/Layout/Stack";
import CategoryPostSeo from "./CategoryPostSeo";
import withCategoryPostTracking from "theme/modules/Posts/CategoryPost/withCategoryPostTracking";
import Breadcrumb from "theme/components/organisms/Breadcrumb";

const CategoryPost = (props) => {
  return (
    <div className="category-post">
      <CategoryPostSeo categoryPost={props.categoryPost} />
      <LayerPage
        key={props.id}
        loading={props.loading}
        layer={props.categoryPost.layerPosts}
        postsPerPage={props.categoryPost.posts_per_page}
        header={
          <Stack size="2">
            <div className="layer-header">
              <div className="layer-header__title">
                <WysiwygV2
                  containerClass={`category-post-content category-post--category${props.id}`}
                  content={props.categoryPost.contentWysiwyg}
                />
              </div>
            </div>
          </Stack>
        }
      >
        <PostList posts={props.posts} loading={props.loading} />
      </LayerPage>
      <WysiwygV2
        containerClass={`category-post_footercontent category-post_footer${props.id}`}
        content={props.categoryPost.footerContentWysiwyg}
      />
      <Breadcrumb paths={props.categoryPost.breadcrumb} />
    </div>
  );
};

export default compose(
  withRouter,
  EnhanceCategoryPost({
    CategoryPostQuery,
  }),
  Loadable((props) => !props.loading || !!props.categoryPost),
  withCategoryPostTracking()
)(CategoryPost);
