import React, { useEffect } from "react";

export const HERO_BANNER_THEME_MAP = {
  1: 'black',
  2: 'white'
};
export const HERO_BANNER_DEFAULT_THEME = 'black';

/**
 *  Spy if a element with attribute "kha" (Kaporal Header Animation)
 *  intersect with the upper DOM.
 *  When intersection is effective we had a attribute to the header of the
 *  value of kha attribute.
 */
const withHeaderAnimation = () => (BaseComponent) => (props) => {
  // Callback for intersection effects.
  const intersectionCallback = (entries) => {
    const reactHeader = document.getElementById("header");
    entries?.forEach(entry => {
      const targetElement = entry.target;
      let className = targetElement.getAttribute('kha') || '';

      // Element intersect ?
      let doesOverlap =
          entry.boundingClientRect.top - reactHeader.clientHeight <= 0
        && entry.boundingClientRect.bottom > 0;

      if (
        doesOverlap && entry.isIntersecting
        && !targetElement.getAttribute('isOverlaping')
      ) {
        reactHeader.setAttribute('kha', className);
        targetElement.setAttribute('isOverlaping', true);
      }

      if (!doesOverlap && targetElement.getAttribute('isOverlaping')) {
        targetElement.removeAttribute('isOverlaping');
        if (reactHeader.getAttribute('kha') === className) {
          reactHeader.removeAttribute('kha');
        }
      }
    })
  }

  // Create intersection observer only WEB side.
  const intersectionObserver = !process.env.SERVER && new IntersectionObserver(
    intersectionCallback,
    {
      root: null,
      threshold: [0, 0.1, 0.2, 0.8, 0.9, 1],
    }
  );

  // On loading create effects.
  useEffect(() => {
    // Avoid SSR errors.
    if (process.env.SERVER) return;
    // Get all elements with "kha" attribute.
    const targets = document.querySelectorAll("[kha]");
    // No target ? ok get out !
    if (!targets.length) return;
    // Attach observer.
    targets?.forEach(target => intersectionObserver.observe(target));
    return () => {
      targets?.forEach(_ => intersectionObserver.disconnect())
    };
  });

  return (
    <BaseComponent {...props} />
  );
}

export const withHeroEffect = () => (BaseComponent) => ({ heroEffect, ...props }) => {
  if (!heroEffect) return <BaseComponent {...props} />;
  return (
    <div
      {...(
        !!parseInt(heroEffect) &&
        {
          kha: HERO_BANNER_THEME_MAP[heroEffect] || HERO_BANNER_DEFAULT_THEME,
        }
      )}
    >
      <BaseComponent {...props} />
    </div>
  )
}

// export { withHeaderAnimation, withHeroEffect };
export default withHeaderAnimation;