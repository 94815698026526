import config from "config/website";

const excludeTaxFrom = (price) =>
  parseFloat(parseFloat(price / (config.tax || 1)).toFixed(2));

const buildFacetQueryStringParameters = (attributes, knownFacetParameters) => {
  const filteredAttributes = attributes.filter((attribute) =>
    knownFacetParameters.includes(attribute.name)
  );

  return filteredAttributes.reduce((queryStringParameters, attribute) => {
    attribute.value.forEach((value, index) => {
      queryStringParameters = {
        ...queryStringParameters,
        [`${attribute.name}[${index}]`]: value,
      };
    });

    return queryStringParameters;
  }, {});
};

const parseSortQueryParameter = (sort) => {
  if (!sort) {
    return {};
  }
  try {
    return JSON.parse(sort);
  } catch (e) {
    console.warn(
      "sort query parameter is invalid JSON, falling back on no sorting"
    );
    return {};
  }
};

class QueryHelper {
  constructor() {
    this.params = {};
    this.facets = {};
    this.page = 0;
    this.fixedQueryParameters = [
      "page",
      "pageLength",
      "minPrice",
      "maxPrice",
      "sort",
    ];
    this.defaultPageLength = Array.isArray(config.available_page_sizes)
      ? config.available_page_sizes[0]
      : 9;
  }

  makeNew(query) {
    this.params.size =
      query && query.pageLength
        ? parseInt(query.pageLength, 10)
        : this.defaultPageLength;
    this.page = query && query.page ? parseInt(query.page, 10) : 1;
    this.params.page = this.page;
    this.params.from = (this.page - 1) * this.params.size;
    this.params.priceRange = null;
    if (query.minPrice || query.maxPrice) {
      this.minPrice = query.minPrice;
      this.maxPrice = query.maxPrice;
      this.params.priceRange = {
        min: query.minPrice ? excludeTaxFrom(query.minPrice) : null,
        max: query.maxPrice ? excludeTaxFrom(query.maxPrice) : null,
      };
    }

    if (Object.prototype.hasOwnProperty.call(query, "sort")) {
      this.params.sort = parseSortQueryParameter(query.sort);
    }
    const attributeFilters = Object.assign({}, query);
    const isAttribute = (code) =>
      this.fixedQueryParameters.indexOf(code) === -1;

    this.params.attributes = Object.keys(attributeFilters)
      .filter(isAttribute)
      .map((attributeName) => ({
        name: attributeName,
        value: [].concat(attributeFilters[attributeName]),
      }));

    return this;
  }

  filterCanonicalQueryStringParameters(
    knownFacetParameters,
    shouldQueryIncludeAdditionalParameters = true
    ) {
    const facetParameters = buildFacetQueryStringParameters(
      this.params.attributes,
      knownFacetParameters
    );
    return {
      page: this.page !== 1 ? this.page : undefined,
      ...(shouldQueryIncludeAdditionalParameters
        ? {
            pageLength:
              this.params.size !== this.defaultPageLength
                ? this.params.size
                : undefined,
            ...facetParameters,
         }
        : {}),
    };
  }

  withFacets(args) {
    this.facets = args;
    return this;
  }

  getFacetsParams() {
    return {
      ...this.facets,
      params: {
        ...this.params,
        // Filter out sort parameters that are not defined in the GraphQL schema's QueryInput sort field.
        sort: {
          field: this.params.sort?.field,
          direction: this.params.sort?.direction,
        },
      },      
    };
  }

  getCurrentPage() {
    return this.page;
  }

  getSize() {
    return this.params.size;
  }

  getFrom() {
    return this.params.from;
  }

  getSort() {
    return this.params.sort;
  }

  hasFilter() {
    return this.params.attributes.length > 0 || this.params.priceRange !== null;
  }
}

export default QueryHelper;
