module.exports = {
  analytics: {
    enable: true,
    debug: process.env.NODE_ENV !== "production",
    defaultSettings: {},
    integrations: [
      {
        // The name allows to know if the user allowed to use
        // this tracking service or not
        name: "google-tag-manager",
        // Usually we always need to set it to true since GDPR
        needConsent: true,
        // Some integrations may have a privacy mode allowing them
        // to be enabled by default. Use this configuration to always
        // enable it. It is irrelevant if `needConsent` is `false`.
        enabledByDefault: true,
        settings: () =>{
          // Settings needed by the integration
          // The fact that it the key "Google Analytics" is
          // defined by the integration itself
          // Note: otherAuthorizations = ['other-code', 'other-code-2']
          return {
            "Google Tag Manager": {
              containerId: process.env.FRONT_COMMERCE_WEB_GTM_CONTAINER_ID || "GTM-NN555JJ",
              // userConsents: otherAuthorizations,
              // anonymizeIp: true,
            }
          };
        },

        // integration that will add itself to the `analytics.js` lib
        script: () =>
          import("@segment/analytics.js-integration-google-tag-manager"),
      },
      // You can add other integrations here. They are loaded asynchronously and
      // won't impact the user's performance too much (as long as there are not
      // too many).
      {
        name: "abtasty",
        needConsent: true,
        enabledByDefault: false,
        settings: () => {
          // Those settings should be retrieved from magento back.
          return {
            "ABTasty" : {
              abtastyID: 'efbe56a2cf4d9366914bdde60275f4e1',
            },
          };
        },
        script: () =>
          import("src/web/analytics/abtasty.js"),
      },
      {
        name: "olapic",
        needConsent: true,
        enabledByDefault: false,
        settings: () => {
          // Those settings should be retrieved from magento back.
          return {
            "Olapic" : {
              checkoutUrl: "//photorankstatics-a.akamaihd.net/static/frontend/checkout/olapic.checkout.helper.js",
              apikey: "80616c6267d01f094e843e0e86931a75f0a9fc899ec9a8046c3348cb758a06ae",
              page: {
                gallery: "dbe6eb9b75d6daf2514be032673e174b",
                home: "7a9e4ee366cf3658bbb0c8941aa9e85a",
              },
              event: {
                'Product Viewed': "5f5d5417e6b2cf7066eb778f755307f5",
              },
              wrapper: "olapic_specific_widget",
            },
          };
        },
        script: () =>
          import("src/web/analytics/olapic.js"),
      },
      {
        name: "adobeStoreFrontEvent",
        needConsent: true,
        enabledByDefault: true,
        settings: (authorization) => {
          return {
            "AdobeStoreFrontEvent" : {
              authorization: authorization
            },
        }},
        script: () =>
          import("src/web/analytics/adobeStoreFrontEvent.js"),
      },
    ],
  },
};
