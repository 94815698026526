import { compose, mapProps, setPropTypes } from "recompose";
import PropTypes from "prop-types";
import makeQueryFromLocation from "./makeQueryFromLocation";

const EnhanceLayerCategoryPost = () =>
  compose(
    setPropTypes({
      location: PropTypes.shape({
        search: PropTypes.string,
        pathname: PropTypes.string.isRequired,
      }),
    }),
    mapProps(({ categoryPost, posts_per_page, ...props }) => {
      const defaultParams = {
        pageLength: categoryPost?.posts_per_page || posts_per_page,
      };
      const query = makeQueryFromLocation(props.location, defaultParams);

      return {
        ...props,
        categoryPost,
        page: query.getCurrentPage(),
        length: query.getSize(),
        variables: query.getFacetsParams(),
      };
    })
  );

export default EnhanceLayerCategoryPost;
