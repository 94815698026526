import React from "react";
import { compose } from "recompose";
import Loadable from "theme/components/helpers/Loadable";
import Breadcrumb from "theme/components/organisms/Breadcrumb";
import PostPageSeo from "theme/modules/Posts/PostPageSeo/PostPageSeo.js";
import WysiwygV2 from "theme/modules/WysiwygV2";
import withPostTracking from "theme/modules/Posts/withPostTracking/withPostTracking";

const PostHeader = ({ title }) => (
  <div className="page-title post-title">
    <h1 itemProp="headline" className="has-border">
      <div className="h1-text">
        <WysiwygV2 content={title} />
      </div>
    </h1>
  </div>
);

const PostPage = (props) => {
  return (
    <div itemScope itemType="http://schema.org/WebPage">
      <PostPageSeo post={props.post} />
      <div className="page-content page-post">
        <div className="blog-post-container">
          {props.post.enableDefaultHeader && (
            <PostHeader title={props.post.titleWysiwyg} />
          )}
          <article itemProp="mainContentOfPage">
            <WysiwygV2
              containerClass={`kaporalmrs-blog-post-view blog-post-${props.id}`}
              content={props.post.contentWysiwyg}
            />
          </article>
        </div>
      </div>
      <Breadcrumb paths={props.post.breadcrumb} />
    </div>
  );
};

export default compose(
  Loadable((props) => !props.loading),
  withPostTracking()
)(PostPage);
