import React from "react";
import PostPage from "theme/pages/Posts/PostPage";
import CategoryPost from "theme/pages/Posts/CategoryPost";

export const dispatchedRoutes = {
  Post: (props) => (
    <PostPage
      id={props.matched.post_id}
      post={props.matched}
      loading={props.loading}
    />
  ),
  CategoryPost: (props) => (
      <CategoryPost
        id={props.matched.category_id}
        categoryPost={props.matched}
        loading={props.loading}
        location={props.location}
      />
    ),
};
