import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { presets } from "config/images";
import ImageComponent from "./ImageComponent";
import { useShop } from "web/core/shop/ShopContext";
import makeImagesProps from "./makeImagesProps";
import useImageStatus from "./useImageStatus";
import { Helmet } from "react-helmet-async";

/**
 * Kaporal override
 *
 * We overrided this component to use the ErrorBoundary on images generally
 * to prevent all the site to go down when image src data
 * is missing.
 *
 * @typedef {object} Props
 * @property {string} src
 * @property {string} alt
 * @property {string} format
 * @property {"default" | "full" | "rounded" | "full-rounded"} appearance
 * @property {string} [bg]
 * @property {boolean} [cover]
 * @property {boolean} [dangerouslyDisableLazyLoad]
 * @property {boolean} [priority]
 * @property {string} [placeholderSrc]
 * @property {string} [errorSrc]
 * @property {string} [className]
 * @property {string} [sizes]
 */

/** @type {React.FunctionComponent<Props>} */
const Image = ({
   src,
   alt,
   format,
   bg,
   cover,
   appearance,
   dangerouslyDisableLazyLoad,
   priority,
   placeholderSrc,
   errorSrc,
   className,
   sizes,
   ...imgProps
}) => {
  dangerouslyDisableLazyLoad = dangerouslyDisableLazyLoad || priority;

  const shop = useShop();

  const imageSrc = useMemo(() => {
    return src || "/images/placeholder-large_1.jpg"; // @TODO use config placeholder by M2
  }, []);

  const images = useMemo(() => {
    return makeImagesProps(
      (shop && shop.imageBaseUrl) || "",
      imageSrc,
      format,
      bg,
      cover,
      appearance.indexOf("full") > -1,
      placeholderSrc,
      errorSrc
    );
  }, [shop, imageSrc, format, bg, cover, appearance, placeholderSrc, errorSrc]);

  const { imageRef, status, size } = useImageStatus(
    images,
    dangerouslyDisableLazyLoad,
    presets[format]
  );

  const ratio =
    size && size.width && size.height ? size.height / size.width : undefined;

  // We can't select easily the source depending on the browser
  // This means that we will preload the one we'd like to use in priority
  // and browser that don't support this format won't have preload.
  // We might need to improve this process in the future especially since
  // Safari is not supporting webp images.
  const sourceToPreload = images.done.sources[0];

  return (
    <>
      {priority ? (
        <Helmet>
          {sourceToPreload && (
            <link
              rel="preload"
              as="image"
              href={images.done.src}
              imagesrcset={sourceToPreload.srcSet}
              imagesizes={sizes}
              type={sourceToPreload.type}
            />
          )}
        </Helmet>
      ) : null}
      <ImageComponent
        ref={imageRef}
        imgProps={imgProps}
        cover={cover}
        appearance={appearance}
        format={format}
        images={images}
        ratio={ratio}
        sizes={sizes}
        alt={alt}
        status={status}
        dangerouslyDisableLazyLoad={dangerouslyDisableLazyLoad}
      />
    </>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  cover: PropTypes.bool,
  format: PropTypes.oneOf(Object.keys(presets)),
  appearance: PropTypes.oneOf(["default", "full", "rounded", "full-rounded"]),
  bg: PropTypes.string,
  dangerouslyDisableLazyLoad: PropTypes.bool,
  priority: PropTypes.bool,
};

Image.defaultProps = {
  appearance: "default",
};

export default Image;
