import { useLocation } from "react-router";
import makeQueryFromLocation from "../makeQueryFromLocation";
import { useMemo } from "react";

const useLayerPagination = (layer, postsPerPage) => {
  const location = useLocation();
  const query = useMemo(
    () => makeQueryFromLocation(location, { pageLength: postsPerPage }, {}),
    [location, postsPerPage]
  );
  const page = query.getCurrentPage();
  const pageSize = query.getSize();

  const amontHasPages = `${query.params.from + 1}-${
    query.params.from + layer.posts?.length
  }`;
  return [page, Math.ceil(layer.total / pageSize), location, amontHasPages];
};

export default useLayerPagination;
