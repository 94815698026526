import React from "react";
import { compose } from "recompose";
import classNames from "classnames";
import getWysiwygComponent from "theme/modules/WysiwygV2/getWysiwygComponent";
import { StyleProvider } from "theme/modules/WysiwygV2/Style";
import withHeaderAnimation, { withHeroEffect } from "web/core/tools/withHeaderAnimation";

const WysiwygV2 = React.memo(({
  containerClass,
  content,
  blockIdentifier,
  ...rest
}) => {
  const isValidContent = Boolean(content?.__typename);
  if (process.env.NODE_ENV === "development" && !isValidContent) {
    console.warn(
      "The `<Wysiwyg>` component was called with an empty content or a string. Please ensure that the content is fully loaded from a GraphQL query using the `WysiwygFragment` for this content field before displaying the `<Wysiwyg>` component"
    );
  }

  const Component = getWysiwygComponent(content?.__typename);
  const styleClass = classNames('wysiwyg', {
    [`${containerClass}`]: containerClass,
    [`${blockIdentifier}`]: blockIdentifier,
  })
  return (
    <StyleProvider
      className={styleClass}
    >
      {isValidContent && <Component content={content} {...rest} />}
    </StyleProvider>
  );
});

export default compose(
  withHeroEffect(),
  withHeaderAnimation()
)(WysiwygV2);
