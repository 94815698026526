import qs from "web/core/utils/queryString";
import QueryHelper from "theme/modules/Layer/queryHelper";

const makeQueryFromLocation = (location, params) => {
  const query = new QueryHelper();
  return query.makeNew({
    ...qs.parse(location.search),
    pageLength: params.pageLength,
  });
};

export default makeQueryFromLocation;
