import React from "react";
import Stack from "theme/components/atoms/Layout/Stack";
import LayerPagination from "./LayerPagination";

const LayerPage = ({
  header,
  layer,
  title,
  children,
  footer,
  postsPerPage,
}) => {
  return (
    <div className="container">
      <div className="layer-page">
        <section className="layer-page__main">
          <Stack size="1">
            {title}
            {header}
            {children}
            <LayerPagination layer={layer} postsPerPage={postsPerPage} />
            {footer}
          </Stack>
        </section>
      </div>
    </div>
  );
};

export default LayerPage;
