import React from "react";
import { Helmet } from "react-helmet-async";
import useFullUrl from "web/core/shop/useFullUrl";

const PostPageSeo = ({ post }) => {
  const fullUrl = useFullUrl(post.path);
  return (
    <Helmet>
      <title>{post.meta_title || post.title}</title>
      {post.meta_keywords && (
        <meta name="keywords" content={post.meta_keywords} />
      )}
      post.meta_description &&
      <meta name="description" content={post.meta_description} />
      <meta property="og:type" content={post.og_type} />
      <meta property="og:title" content={post.og_title} />
      <meta property="og:description" content={post.og_description} />
      <meta property="og:url" content={fullUrl} />
      {post.og_img && <meta property="og:image" content={post.og_img} />}
      {post.path && <link rel="canonical" href={fullUrl} />}
    </Helmet>
  );
};

export default PostPageSeo;
