import React, { useState, useMemo, useCallback, useEffect } from "react";
import { compose } from "recompose";
import { SvgLoader, SvgProxy } from "react-svgmt";
import EnhanceLogo from "./EnhanceLogo";
import LogoSrcQuery from "./LogoSrcQuery.gql";

const LOGO_ALTERNATE = "Kaporal";

/**
 * Logo is now c/c here with fill="currentColor" to enable color change
 * when header change.
 *
 * The svg path element that must change color must have the class "kaporalLogoColor"
 * within.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Logo = ({
  color,
  logoSrc,
  useDefaultLogoBeforeLoading = true,
  configLoading = true,
}) => {
  const [isSvg, setIsSvg] = useState(null);
  const [isSvgLoading, setSvgLoading] = useState(true);

  const logoSrcUrl = useMemo(() => {
    return logoSrc;
  }, [logoSrc]);

  const setSvgLoadingCallback = () => {
    setSvgLoading(false);
  };

  const setIsSvgCallback = useCallback(
    (logoSrc) => {
      const regex = /(\.svg)$/gm;
      if (!logoSrc || logoSrc?.match(regex)?.[0]) {
        setIsSvg(true);
      } else {
        setIsSvg(false);
        setSvgLoading(false);
      }
    },
    [setIsSvg, setSvgLoading]
  );

  // Check if the url contains a svg file.
  useEffect(() => {
    setIsSvgCallback(logoSrcUrl);
  }, [logoSrcUrl, setIsSvgCallback]);

  /**
   * Default logo as inline SVG.
   *
   * @param color
   * @returns {JSX.Element}
   * @constructor
   */
  const DefaultLogo = ({ color }) => {
    const logoColor = color;
    const logoKey = Math.floor(Math.random() * 10000000000000000);
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 475.73 86.7">
        <defs>
          <style>
            {`.cls-1--${logoKey} {
              fill: ${logoColor};
              stroke-width: 0px;
            }`}
          </style>
        </defs>
        <g>
          <g>
            <path
              class={`cls-1--${logoKey}`}
              d="M91.72,1.54l-27.12,73.88-29.18-40.34L68.47,1.55h-18.3L14.76,38.62V1.55H0v82.63h14.76v-27.98l10.39-10.86,27.86,38.84h17.94v-.02s4.84,0,4.84,0l7.2-20.66h31.52l7.2,20.66h14.99L106.83,1.54h-15.11ZM87.12,51.71l6.61-20.07c2.48-7.55,4.25-14.17,4.96-15.94h.23c.47,1.54,2.48,8.14,5.08,15.94l6.61,20.07h-23.49Z"
            />
            <path
              class={`cls-1--${logoKey}`}
              d="M190.88,6.49c-5.78-4.13-11.8-4.96-20.78-4.96h-32.7v82.63h14.52v-31.64h18.18c7.91,0,14.28-.71,19.6-4.01,7.67-4.72,10.86-12.87,10.86-21.25,0-7.91-2.95-15.82-9.68-20.77ZM180.5,38.84c-3.89,2.01-8.85,2.12-12.87,2.12h-15.7V13.22h15.47c6.14,0,9.56.24,13.1,2.12,3.78,2.01,6.26,5.67,6.26,11.69s-2.36,9.92-6.26,11.8Z"
            />
            <path
              class={`cls-1--${logoKey}`}
              d="M240.25,0c-23.96,0-38.72,18.3-38.72,42.85s13.93,42.85,38.72,42.85,38.84-17.83,38.84-42.85S264.33,0,240.25,0ZM240.25,74.13c-16.29,0-23.85-14.28-23.85-31.28s7.67-31.28,23.85-31.28,23.96,14.64,23.96,31.28-7.56,31.28-23.96,31.28Z"
            />
            <path
              class={`cls-1--${logoKey}`}
              d="M373.38,1.54l-27.96,76.15-1.48-2.43-14.41-28.58c10.24-3.53,15.48-10.71,15.48-21.3,0-18.34-12.4-23.8-36.75-23.8h-26.29v82.57h14.45v-34.74h14c1.48,0,2.96,0,4.32-.11l17.41,34.85h10.9v.03s14.39,0,14.39,0l7.2-20.66h31.52l7.2,20.66h14.99L388.49,1.54h-15.11ZM310.76,37.9h-14.34V13.18h13.88c11.37,0,19.91,1.48,19.91,12.3,0,9.91-7.05,12.41-19.46,12.41ZM368.78,51.71l6.61-20.07c2.48-7.55,4.25-14.17,4.96-15.94h.23c.47,1.54,2.48,8.14,5.08,15.94l6.61,20.07h-23.49Z"
            />
            <polygon
              class={`cls-1--${logoKey}`}
              points="433.7 1.54 419.07 1.54 419.07 84.17 475.73 84.17 475.73 72.48 433.7 72.48 433.7 1.54"
            />
          </g>
        </g>
      </svg>
    );
  };

  // While config info are loading we use (or not) the default svg inline logo.
  if (configLoading) {
    if (useDefaultLogoBeforeLoading) {
      return (
        <div className="logo">
          <DefaultLogo color={color} />
        </div>
      );
    } else {
      return null;
    }
  }

  // Without URL in BO we display the basic default logo.
  if (!logoSrcUrl) {
    return (
      <div className="logo">
        <DefaultLogo color={color} />
      </div>
    );
  }

  // If it's not an SVG file, we display it as a basic image file.
  if (isSvg === false && logoSrcUrl) {
    return (
      <div className="logo">
        <img src={logoSrcUrl} alt={LOGO_ALTERNATE} />
      </div>
    );
  }

  // If it's a SVG file we display it as the component, with the default logo while loading.
  return (
    <div className="logo">
      {isSvgLoading && useDefaultLogoBeforeLoading && (
        <DefaultLogo color={color} />
      )}
      {logoSrcUrl && (
        <div className={isSvgLoading ? "hidden" : ""}>
          <SvgLoader path={logoSrcUrl} onSVGReady={setSvgLoadingCallback}>
            <SvgProxy
              selector={".kaporalLogoColor"}
              fill={color || "currentColor"}
            />
          </SvgLoader>
        </div>
      )}
    </div>
  );
};

export default compose(
  EnhanceLogo({
    LogoSrcQuery,
  })
)(Logo);
