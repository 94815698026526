import React from "react";
import { H1 } from "theme/components/atoms/Typography/Heading";
import PageQuery from "theme/pages/Home/PageQuery.gql";
import EnhanceNotFound from "./EnhanceNotFound";
import WysiwygV2 from "theme/modules/WysiwygV2";

const NotFound = (props) => {
  return (
    <div>
      <div className="page-content">
        {
          props?.cms &&
          <div className="container">
            {props.cms.content_heading && (
              <H1 itemProp="headline">{props.cms.content_heading}</H1>
            )}
            <WysiwygV2
              containerClass="cms-page"
              content={props.cms.contentWysiwyg}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default EnhanceNotFound({ PageQuery })(NotFound);
