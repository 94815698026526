import makeTrackers from "./makeTrackers";
import config from "config/analytics";

if (module.hot) {
  // Instead of worrying about analytics settings diffing on hot reload,
  // we simply reload the page. This is also be the case when one of
  // the integration's script is changed.
  module.hot.accept("config/analytics", () => {
    window.location.reload();
  });
}

let previousAuthorizedIntegrations = null;
let resolveAnalytics;
let analyticsReady = new Promise((resolve) => {
  resolveAnalytics = resolve;
});

if (
  !process.env.SERVER &&
  process.env.NODE_ENV === "development" &&
  process.env.FRONT_COMMERCE_WEB_DEV_ANALYTICS_WARNING_DISABLE !== "true"
) {
  if (
    config.analytics &&
    config.analytics.enable &&
    config.analytics.integrations &&
    config.analytics.integrations.length > 0
  ) {
    import("common-tags")
      .then(({ stripIndent }) => {
        const timeout = setTimeout(() => {
          console.warn(
            stripIndent`
              Analytics: It seems that you didn't start analytics system.
              This is most likely because you don't use CookieLink in your page. You can either:
              * add back "theme/modules/Cookie/CookieLink"
              * call "web/core/analytics::initAnalytics" in your own component
              * disable this warning by setting FRONT_COMMERCE_WEB_DEV_ANALYTICS_WARNING_DISABLE=true
            `
          );
        }, 1000);

        analyticsReady.then(() => clearTimeout(timeout));
      })
      .catch((e) => console.error(e));
  }
}

const isAuthorized = (authorizedIntegrations, { name }) => {
  return authorizedIntegrations.some(
    ({ name: authorizedName }) => authorizedName === name
  );
};

// STAIM fix to reload when new Authorizations are set.
const shouldReload = (previousAuthorizations, nextAuthorizations) => {
  const longestAuthArray =
    previousAuthorizations.length >= nextAuthorizations.length
      ? previousAuthorizations
      : nextAuthorizations;
  const shortestAuthArray =
    previousAuthorizations.length < nextAuthorizations.length
      ? previousAuthorizations
      : nextAuthorizations;

  return longestAuthArray.some(
    (integration) => !isAuthorized(shortestAuthArray, integration)
  );
};

const isSameAuthorizations = (previousAuthorizations, nextAuthorizations) => {
  return (
    previousAuthorizations.length === nextAuthorizations.length &&
    previousAuthorizations.every((integration) =>
      isAuthorized(nextAuthorizations, integration)
    )
  );
};

const smartRequire = (module, name) => {
  if (typeof module === "function") {
    return module;
  } else if (module.__esModule) {
    if (typeof module.default === "function") {
      return module.default;
    }
  }

  console.error(
    `Invalid script defined for analytics script ${JSON.stringify(
      name
    )}. The script key should be a function that either return the promise for the integration itself or a dynamic import of an ES module that exports the integration as its default export.`
  );
};

const loadSettings = (analytics, authorizedIntegrations, authorizations) => {
  const authorizedCodes = authorizedIntegrations.map(({ name }) => name);
  const authorizedCodesExcluding = (code) =>
    authorizedCodes.filter((value) => value !== code);

  return Promise.all(
    authorizedIntegrations
      .map((integration) => {
        return async (analytics) => {
          if (integration.script) {
            const integrationScript = smartRequire(
              await integration.script(),
              integration.name
            );
            analytics.use(integrationScript);
          }

          const settings = integration.settings;
          return typeof settings === "function"
            ? settings(
                authorizations[integration.name],
                authorizedCodesExcluding(integration.name)
              )
            : settings;
        };
      })
      .map((makeIntegrationSettings) => {
        return makeIntegrationSettings(analytics);
      })
  ).then((integrationsSettings) => {
    return Object.assign({}, ...integrationsSettings);
  });
};

const initAnalytics = async (authorizations = {}) => {
  if (!process.env.SERVER && process.env.NODE_ENV !== "test") {
    if (!config.analytics || !config.analytics.enable) {
      return null;
    }

    if (config.analytics.integrations?.length > 0) {
      const authorizedIntegrations = config.analytics.integrations.filter(
        (integration) =>
          integration.enabledByDefault ||
          !integration.needConsent ||
          authorizations[integration.name]
      );

      if (previousAuthorizedIntegrations) {
        if (
          shouldReload(previousAuthorizedIntegrations, authorizedIntegrations)
        ) {
          if (window) {
            window.location.reload();
            return;
          }
        }

        if (
          isSameAuthorizations(
            previousAuthorizedIntegrations,
            authorizedIntegrations
          )
        ) {
          return;
        }
      }

      previousAuthorizedIntegrations = authorizedIntegrations;

      return import("analytics.js").then(({ default: analytics }) => {
        return loadSettings(
          analytics,
          authorizedIntegrations,
          authorizations
        ).then((settings) => {
          if (config.analytics.debug) {
            // eslint-disable-next-line no-console
            console.log("DEBUG: Analytics authorizations", authorizations);
            // eslint-disable-next-line no-console
            console.log("DEBUG: Analytics authorized settings", settings);
            analytics.debug();
          }

          analytics.init({
            ...config.analytics.defaultSettings,
            ...settings,
          });

          resolveAnalytics(analytics);
        });
      });
    }
  }
};

const { trackEvent, trackPage } = makeTrackers(analyticsReady);

export { trackPage, trackEvent, initAnalytics };
