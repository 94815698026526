import React from "react";
import Image from "theme/components/atoms/Image";
import WysiwygV2 from "theme/modules/WysiwygV2";
import Link from "theme/components/atoms/Typography/Link";

const NewsCard = ({
  thumbnail,
  title,
  titleWysiwyg,
  createdAt,
  path,
  tags,
  featuredImgAlt,
  count,
}) => {
  const tagsText = tags.map(({ title }) => title);

  return (
    <article className={`post-list-item item item-${count}`}>
      <Link to={path}>
        <div className="image-container">
          <div className="content-container">
            <div className="header-container">
              <div className="month-title">
                {!!tags.length && (
                  <span className="title">{tagsText.join(", ")}</span>
                )}
                <time dateTime={createdAt} className="month">
                  {createdAt}
                </time>
              </div>
              <div className="container-title-card">
                {titleWysiwyg ? (
                  <WysiwygV2 content={titleWysiwyg} />
                ) : (
                  <div class="wysiwyg">{title}</div>
                )}
              </div>
            </div>
          </div>
          <Image
            dangerouslyDisableLazyLoad={false}
            itemProp="image"
            alt={featuredImgAlt || title}
            src={thumbnail}
            format="imageGalleryNoRatio"
          />
        </div>
      </Link>
    </article>
  );
};

export default NewsCard;
