import { compose, branch, setPropTypes, withProps } from "recompose";
import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import withLayerFromLocation from "theme/modules/Posts/CategoryPost/Layer/withLayerFromLocation";
import withEntityNotFound from "theme/modules/PageError/NotFound/withEntityNotFound";
import withErrorFallback from "theme/modules/PageError/withErrorFallback";

const EnhanceCategoryPost = ({ CategoryPostQuery }) => {
  const withAlreadyFetchedCategoryPost = compose(
    withProps((props) => {
      return {
        categoryId: props.id || props.match.params.id,
      };
    }),
    withLayerFromLocation()
  );

  const withFetchCategoryPost = compose(
    withRouter,
    setPropTypes({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
    }),
    withProps((props) => ({
      categoryId: props.id || props.match.params.id,
      postsPerPage: props.postsPerPage,
      facetsParams: {
        id: Number(props.id || props.match.params.id),
      },
    })),
    withEntityNotFound({
      isFound: (props) => /^\d+$/.test(props.categoryId),
    }),
    withLayerFromLocation(),
    graphql(CategoryPostQuery, {
      options: ({ variables, categoryId }) => {
        return {
          variables: {
            id: Number(categoryId),
            ...variables,
          },
        };
      },
      props: ({ data }) => {
        const hasResult =
          !data.loading ||
          (typeof data.categoryPost !== "undefined" && !data.error);

        return {
          loading: data.loading,
          error: data.error,
          categoryPost: hasResult && data.categoryPost,
        };
      },
    }),
    withErrorFallback({
      hasError: (props) => props.error,
    }),
    withEntityNotFound({
      isFound: (props) => props.error || props.loading || props.categoryPost,
    })
  );

  return compose(
    branch(
      (props) => props.categoryPost,
      withAlreadyFetchedCategoryPost,
      withFetchCategoryPost
    ),
    withProps((props) => {
      const layerPosts = props.categoryPost && props.categoryPost.layerPosts;

      return {
        posts: layerPosts ? layerPosts.posts : [],
        postsCount: layerPosts && layerPosts.total,
      };
    })
  );
};

export default EnhanceCategoryPost;
